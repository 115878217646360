import React from "react";
import EditTeamClient from "./EditTeamClient";

const ManageClient = ({ users, fetchClientTeam, type }) => {

    return (
        <>
            <div className="profileBlock-wrap profileBlock-wrap-last">
                <h2 className="infoTitle">Manage {type ? type : 'Client'} Accounts </h2>
                <div className="profileBlock tableWrap mt-0" style={{overflowX:"auto", scrollbarWidth:"thin"}}>
                    <table className="table table-striped table-dark small-table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">Created</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.length > 0 ?
                                    users.map((user, index) => {
                                        return (
                                            <>
                                                <EditTeamClient
                                                    type={`${type ? type : 'Client'} Account`}
                                                    user={user}
                                                    index={index}
                                                    fetchClientTeam={fetchClientTeam}
                                                    key={`client_${index}`}
                                                />
                                            </>
                                        )
                                    })
                                    : ''
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ManageClient;