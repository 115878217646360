import React from "react";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { appName } from "../../global/global";
import { Helmet } from "react-helmet";

const Training = ({ location }) => {

    const supportData = useSelector(state => state.support.articles);
    const articleId = queryString.parse(location.search).id;
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Training </title>
            </Helmet>            <Navbar />

            <section className="siteWrap">
                <div className="tabCont">
                    <div className="row">

                        <TrainingTitles location={location} id={articleId} supportData={supportData} name={false} />
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Training;