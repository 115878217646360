import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddAccount from "./AddAccount";
import TitleBar from "../TitleBar";
import { appName } from "../../global/global";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const AccountManagement = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Acount Management </title>
            </Helmet>            <Navbar />

            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <AddAccount />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AccountManagement;