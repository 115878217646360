import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import { loadUser } from "../../actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { appName } from "../../global/global";
import { Helmet } from "react-helmet";


const Dashboard = () => {
    const dispatch = useDispatch()
    const brandName = useSelector(state => state.rebrand.data);

    const [campLength, setCampLength] = useState(0)
    useEffect(() => {
        dispatch(loadUser())
    }, [])


    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Dashboard </title>
            </Helmet>
            <Navbar />
            <section className="siteWrap">
                <DashboardHead campLength={campLength} />
                <DashboardContent setCampLength={setCampLength} />
                <DashboardAbout />
            </section>
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;