import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onUpdateWlUserPassword, onUpdateWlUserProfile } from '../../actions/WhiteLabelActions'

const EditUser = ({ curElem, show, handleClose, fetchUsers }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        name: "",
        email: "",
    })
    const [pass, setPass] = useState({
        password: '',
        new_password: '',
        confirm_password: ''
    })
    const [loader, setLoader] = useState({
        submitProf: false,
        submitPass: false
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleSubmitProf = (e) => {
        e.preventDefault()
        let obj = { ...state }
        delete obj.email
        setLoader({
            ...loader,
            submitProf: true
        })
        dispatch(onUpdateWlUserProfile(obj, fetchUsers, loader, setLoader))
    }

    const handleSubmitPass = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (pass.new_password) {
            if (pattern.test(pass.new_password)) {
                if (pass.new_password === pass.confirm_password) {
                    setLoader({
                        ...loader,
                        submitPass: true
                    })
                    dispatch(onUpdateWlUserPassword(pass, fetchUsers, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }
    useEffect(() => {
        if (curElem) {
            setState({
                ...state,
                name: curElem.name,
                email: curElem.email,
                id: curElem.id
            })

            setPass({
                ...pass,
                id: curElem.id
            })
        }
    }, [curElem])

    return (
        <Modal className="white_label_modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <span className="white_modalClose text-end d-block" onClick={handleClose}><AiOutlineClose /></span>

                <form className="white-block mt-3" onSubmit={handleSubmitProf}>
                    <div className="white-titleBar-left">
                        <h2>Update User Info</h2>
                    </div>
                    <div className="white-input-wrap">
                        <label htmlFor="">Name</label>
                        <div className="white-outer">
                            <input
                                type="text"
                                className="white_inp"
                                placeholder="Enter Your Name"
                                name='name'
                                value={state.name}
                                onChange={(e) => setState({ ...state, name: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="white-input-wrap mt-2">
                        <label htmlFor="">Email</label>
                        <div className="white-outer">
                            <input
                                type="email"
                                className="white_inp"
                                value={state.email}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="white-input-wrap text-center text-sm-end">
                        <button className="white-theme-btn" type='submit'><span>{loader.submitProf ? <>Updating <FaSpinner className="spin" /></> : "Update"}</span></button>
                    </div>
                </form>

                <form className="white-block" onSubmit={handleSubmitPass}>
                    <div className="white-titleBar-left">
                        <h2 >Update Password</h2>
                    </div>

                    <div className="white-input-wrap">
                        <label htmlFor="">Current Password</label>
                        <div className="white-outer">
                            <input
                                className="white_inp"
                                type="password"
                                name='password'
                                placeholder='Enter Current Password'
                                onChange={(e) => setPass({ ...pass, password: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="white-input-wrap">
                        <label htmlFor="">New Password</label>
                        <div className="white-outer">
                            <input
                                className="white_inp"
                                type="password"
                                name='new_password'
                                placeholder='Enter New Password'
                                onChange={(e) => setPass({ ...pass, new_password: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="white-input-wrap">
                        <label htmlFor="">Re-type New Password</label>
                        <div className="white-outer">
                            <input
                                className="white_inp"
                                type="password"
                                placeholder='Confirm Password'
                                name='confirm_password'
                                onChange={(e) => setPass({ ...pass, confirm_password: e.target.value })}
                                required
                            />
                        </div>
                    </div>
                    {passwordMsg.validation ?
                        <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                    <div className="white-input-wrap text-center text-sm-end">
                        <button className="white-theme-btn"><span>{loader.submitPass ? <>Updating <FaSpinner className="spin" size={14} /> </> : "Update"}</span></button>
                    </div>

                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditUser