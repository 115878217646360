import React, { useState } from "react";
import "../../../src/App.css"
import { Link, useHistory } from "react-router-dom";
import LoginFooter from "./LoginFooter";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/authAction";
import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";
import { appName } from "../../global/global";
import { Helmet } from "react-helmet";


const Login = () => {

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const brandName = useSelector(state => state.rebrand.data);
    const rebrandData = useSelector(state => state.rebrand.data);
    if (auth.isAuthenticated) {
        history.push('/dashboard');
    }

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        setLoader(true);
        dispatch(loginUser(user, setLoader));
    }



    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Login </title>
            </Helmet>
            {/* <TitleBar title="Login" /> */}

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        {/* <img src={logo} alt="" /> */}
                        <img
                            className="img-fluid"
                            src={rebrandData?.logo || logo}
                            alt="Logo"
                            height="auto"
                        />
                    </div>
                </div>
            </header>

            <div className="login-container d-flex align-items-center">
                <div className="container d-flex justify-content-center align-items-center flex-column flex-lg-row gap-5">
                    {/* <div className="row full-height-row align-items-center"> */}
                        {/* <div className="col-lg-6"> */}
                            <div className="login-container-left login-content text-white text-center w-100">
                                <h1><span>Welcome To </span>{rebrandData?.name ? rebrandData?.name : appName}!</h1>
                                <p>Create Incredible Videos On Scale Using AI & Just a Keyword!
                                    Dominate Your Niche With Short Engaging Videos!
                                </p>
                            </div>
                        {/* </div> */}
                        {/* <div className="col-lg-6"> */}
                            <div className="login-container-right w-100 px-2 px-sm-5 px-lg-0">
                                <div className="login-container-right-main">
                                    <h2><span>Login</span> to Your Account Now</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input type="email" name="email" className="form-control"
                                                placeholder="Email Address" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="************" required
                                                onChange={(e) => onInputChange(e)}
                                                maxLength={32}
                                            // minLength={6}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Sign in {rebrandData?.name ? rebrandData?.name : appName}

                                                {/* {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Sign in ContentReel */}
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink"><Link to="/forget-password" className="text-center "> Forgot Password ?</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            {/* </div> */}


            <LoginFooter />

        </>
    )
}

export default Login;