import React, { useEffect, useState } from "react";
import ReelAppsLogo from '../images/ReelApps.png';
import { useSelector } from "react-redux";

const Footer = () => {

    const rebrandData = useSelector(state => state.rebrand.data);
    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <footer className="appFooter innerPage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="footer-single">
                                <ul className="footer-list-left d-flex align-items-center justify-content-center justify-content-lg-start">
                                    <li><a href="https://reelapps.io/terms.html" target="_blank" rel="noreferrer">TERMS </a> | </li>
                                    <li><a href="https://reelapps.io/privacy.html" target="_blank" rel="noreferrer">PRIVACY </a> | </li>
                                    <li><a href="http://support.vineasx.com/" target="_blank" rel="noreferrer">SUPPORT </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 my-2 my-lg-0">
                            <div className="footer-single">
                                <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>

                                <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank" rel="noreferrer">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank" rel="noreferrer">Vega6</a></p>

                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-single reelLogo">
                                <a
                                    className="text-white"
                                    target="blank"
                                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                                    href="https://reelapps.io/"
                                >
                                    <img
                                        src={rebrand.logo || ReelAppsLogo}
                                        className="img-fluid"
                                        style={{ height: "40px" }}
                                        alt="" />

                                </a>
                                {/* <a href="https://reelapps.io/" target="_blank" rel="noreferrer"><img src={ReelAppsLogo} alt={'logo'} /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default React.memo(Footer);