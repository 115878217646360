import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../actions/authAction";
import { Dropdown } from 'react-bootstrap';
import logo from '../../src/images/LOGO.png';
import noProfileImage from '../../src/images/no-profile.png';
import { MdOutlineInbox } from "react-icons/md";

const Navbar = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    // const rebrandData = useSelector(state => state.rebrand.data);
    const rebrandData = useSelector(state => state.rebrand);
    const [mainIs, setMainIs] = useState(false);
    const [isActive, setActive] = useState("false");
    const [memberShip, setMemberShip] = useState([])
    const [rebrand, setRebrand] = useState(rebrandData);


    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])
    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])
    const handleToggle = () => {
        setActive(!isActive);
    };
    const logout = () => {
        dispatch(logoutUser());
    }

    return (
        <>
            <header className="appHeader">
                <div className="container-fluid">
                    <div className="appHeader-main">
                        <div className="logo">
                            {/* <Link to={'/'}><img className={`${rebrandData ? "logo-image-set" : ""} img-fluid`} src={rebrandData ? rebrandData.logo : logo} alt="" /></Link> */}
                            <Link className="navbar-brand" to="/dashboard">
                                <img src={rebrand.data !== false ? rebrand.data.logo : logo} alt="logo" className="img-fluid " style={{ maxHeight: "46px" }} />
                            </Link>
                        </div>
                        <div className="navExpand" onClick={handleToggle}><i className="fa fa-bars" aria-hidden="true"></i></div>
                        <div className={`appHeader-right ${!isActive ? "act" : ""}`}>
                            <div className="appHeader-nav">
                                <ul>
                                    <li><NavLink className="nav-link" to="/dashboard" activeClassName="active">Dashboard</NavLink></li>
                                    <li><NavLink className="nav-link" to="/projects" activeClassName="active">Projects</NavLink></li>
                                    {
                                        auth.user.is_client_account === "0" ?
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/integration">Integrations</NavLink>
                                            </li>
                                            : ''
                                    }
                                    <li className="nav-item"><NavLink className="nav-link" to="/help-and-support">Help</NavLink></li>
                                </ul>
                            </div>
                            <div className="appHeader-action">

                                <div className="action-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic2">
                                            <div className="dropProfileImage"><img src={auth.user ? auth.user.image : noProfileImage} alt="" /></div>
                                            <div>
                                                <span className="welcometxt">Welcome</span>
                                                <span className="profileName">{auth.user?.name}</span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-dark">
                                            {
                                                auth.user.is_client_account === "1" ? "" :
                                                    <Link className="dropdown-item" to="/profile">
                                                        <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                                    </Link>
                                            }
                                            {
                                                auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    <>

                                                        <Link className="dropdown-item" to="/privacy">
                                                            <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                        </Link>

                                                        {auth.user.isWL === '0' && auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                                            <>
                                                                {
                                                                    memberShip.includes("bundle") ?
                                                                        <Link className="dropdown-item" to="/white-label">
                                                                            <span className="mr-2"><MdOutlineInbox fontSize={20} /></span> White Label
                                                                        </Link> : ""
                                                                }
                                                            </>

                                                            : ""
                                                        }

                                                        {
                                                            auth.user.is_reseller_account === "0" ?
                                                                <>


                                                                    {

                                                                        memberShip.includes("enterprise") || memberShip.includes("bundle") ?
                                                                            <>
                                                                                <Link className="dropdown-item" to="account-management">
                                                                                    <span className="mr-2"><i className="fas fa-users" ></i></span>
                                                                                    Account Management
                                                                                </Link>

                                                                                <Link className="dropdown-item" to="/settings">
                                                                                    <span className="mr-2"><i class="fa fa-gear"></i></span> Settings
                                                                                </Link>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                </>
                                                                : ''

                                                        }
                                                    </>
                                                    : ''
                                            }
                                            {/* {auth.user.is_client_account === '1' || auth.user.is_virtual_account === "1" ? null :
                                                (
                                                    <Link className="dropdown-item" to="/white-label">
                                                        <span className="mr-2"><MdOutlineInbox fontSize={20} /></span> White Label
                                                    </Link>
                                                )
                                            } */}
                                            {
                                                auth.user.is_client_account === "0" && auth.user.is_reseller_account === "0" ?
                                                    memberShip.includes("enterprise") || memberShip.includes("reelapps-live-academy") || memberShip.includes("bundle") || memberShip.includes("unlimited") ?
                                                        <Link className="dropdown-item" to="/upgrades">
                                                            <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                                        </Link> : ''
                                                    : ''
                                            }
                                            {
                                                auth.user.is_client_account === "0" && auth.user.is_reseller_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    memberShip.includes("professional") || memberShip.includes("professional-lite") ?
                                                        <Link to='/reel-merge' className="dropdown-item">
                                                            <span className="mr-2">
                                                                <i className="fas fa-sign-out-alt"></i>
                                                            </span> Reel Merge
                                                        </Link> : "" : ""
                                            }
                                            <Link className="dropdown-item" to="/help-and-support">
                                                <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                            </Link>
                                            <span className="dropdown-divider"></span>
                                            <Link className="dropdown-item" onClick={() => logout()}>
                                                <span className="mr-2">
                                                    <i className="fas fa-sign-out-alt"></i>
                                                </span> Sign Out
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export default React.memo(Navbar);