import axios from "axios";
import { commonAxios } from "../global/CommonAxios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";
import { fetchRebrandData } from "./rebrandAction";
import { fetchSocialAccounts } from "./socialAction";

export const loadUser = () => (dispatch, getState) => {
    if (getState().auth.token) {
        commonAxios("fetch-user", {}, dispatch, getState().auth.token)
            .then((res) => {
                if (res.status) {
                    console.log(res.data)
                    dispatch({ type: 'LOAD_USER_DATA', payload: res.data });

                    dispatch(fetchRebrandData(getState().auth.token))
                    dispatch(fetchSocialAccounts(getState().auth.token))
                }
            }).catch((err) => {
                console.log(err)
            })
    }
}

export const masterLoginUser = (user, setLoader, history) => (dispatch) => {
    commonAxios("master-login", user, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'LOGIN_USER', payload: res.data });
                history.push('/dashboard');
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


export const loginUser = (data, setLoader) => (dispatch) => {
    localStorage.clear();
    commonAxios("login", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGIN_USER', payload: res.data });

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}


export const logoutUser = () => (dispatch, getState) => {
    commonAxios("logout", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGOUT' });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const forgetPassword = (data, setLoader) => (dispatch) => {
    commonAxios("forgot-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg.replace('if', 'If'), "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const resetPassword = (data, setLoader, history) => (dispatch) => {
    commonAxios("reset-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                history.push('/');
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}


export const changeProfile = (data, setProfileStatus) => (dispatch, getState) => {
    commonAxios("update-profile-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_IMAGE', payload: res.data });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setProfileStatus(false);
        }).catch((err) => {
            console.log(err)
            setProfileStatus(false);
        })
}


export const updateName = (data, setLoader) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_NAME', payload: data.name });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}


export const updatePassword = (data, setLoader) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}


export const changeEmail = (data, setLoader) => (dispatch, getState) => {

    commonAxios("change-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_EMAIL', payload: data.email });
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })



}

export const gdprAction = (data, type, setShowDelete) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}${type}`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {

            if (type === 'download-user-data') {
                window.location.href = res.data.file;
            } else {
                dispatch(logoutUser());
            }

            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        console.log(error)
        setShowDelete(false);
    })
}

export const onFetchWhiteLabelData = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-whitelabel-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'ADD_REBRAND', payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
} 